@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBMPlexSans-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBMPlexSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBMPlexSans-Medium.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBMPlexSans-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

