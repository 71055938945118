@charset "UTF-8";

.icon-diagram:before { content: '\e800'; } /* '' */
.icon-right:before { content: '\e800'; } /* '' */
.icon-pencil:before { content: '\e801'; } /* '' */
.icon-left:before { content: '\e801'; } /* '' */
.icon-adduser:before { content: '\e802'; } /* '' */
.icon-copy:before { content: '\e803'; } /* '' */
.icon-discord:before { content: '\e804'; } /* '' */
.icon-facebook:before { content: '\e805'; } /* '' */
.icon-telegram:before { content: '\e806'; } /* '' */
.icon-book:before { content: '\e807'; } /* '' */
.icon-exit:before { content: '\e807'; } /* '' */
.icon-clock:before { content: '\e808'; } /* '' */
.icon-checkmark:before { content: '\e809'; } /* '' */
.icon-cross:before { content: '\e80a'; } /* '' */
.icon-dropdown:before { content: '\e80b'; } /* '' */
.icon-email:before { content: '\e80c'; } /* '' */
.icon-arrowPositive:before { content: '\e80d'; } /* '' */
.icon-arrowNegative:before { content: '\e80e'; } /* '' */
.icon-refresh:before { content: '\e80f'; } /* '' */
.icon-graph:before { content: '\e810'; } /* '' */
.icon-image:before { content: '\e811'; } /* '' */
.icon-warning:before { content: '\e817'; } /* '' */
.icon-calendar:before { content: '\e818'; } /* '' */
.icon-exchange:before { content: '\e819'; } /* '' */
.icon-gear:before { content: '\e81a'; } /* '' */
.icon-arrowBack:before { content: '\e81b'; } /* '' */
.icon-video:before { content: '\e81c'; } /* '' */
.icon-telegram:before { content: '\e820'; } /* '' */
.icon-arrow:before { content: '\e821'; } /* '' */
.icon-profile:before { content: '\e829'; } /* '' */
.icon-exit:before { content: '\e82a'; } /* '' */
.icon-heart:before { content: '\e899'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-wallet:before { content: '𐼖'; } /* '\10f16' */
.icon-instagram:before { content: '𘚞'; } /* '\1869e' */
.icon-twitter:before { content: '𘚟'; } /* '\1869f' */
