@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.nav {
  @include flexbox;
  width: 50%;
  margin: 40px auto;
}

.link {
  padding: 20px;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.content {
  margin: 0 auto;
  text-align: center;
}
