@import './variables';

// Some mixins. For example flexbox
$defaultFlexBoxAlign: center;
$defaultFlexBoxJustify: center;
@mixin flexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin inlineFlexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {
  display: inline-flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin clearList {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin imgCenter {
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center;
}

@mixin titleAuth {
  margin-bottom: 35px;

  font-size: 36px;
  font-weight: 600;
  color: $color-basic;
  text-align: center;
}

@mixin arrowBack {
  position: absolute;
  top: 80px;
  left: 80px;
}

@mixin dotDotDot($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin paginationItem {
  width: 27px;
  height: 27px;
  cursor: pointer;
}

@mixin rotateAnimation {
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
// Media request mixins MobileFirst
@mixin forTablet {
  @media screen and (max-width: 481px) {
    @content;
  }
}

@mixin forDesktopSm {
  @media screen and (max-width: 721px) {
    @content;
  }
}

@mixin forDesktopMiddle {
  @media screen and (max-width: 981px) {
    @content;
  }
}

@mixin forDesktopBig {
  @media screen and (max-width: 1281px) {
    @content;
  }
}

@mixin forDesktopHuge {
  @media screen and (max-width: 1441px) {
    @content;
  }
}
